import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { redirectToAuth0Login, redirectToAuth0Register } from 'frontastic/contexts/authV2UserContext/helpers'

const SignInSignUpGlobal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false)
  const router = useRouter()
  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isModalOpen) {
      closeModal()
    }
  }

  const openLoginModal = () => {
    setIsModalOpen(true)
  }
  const openSignUpModal = () => {
    setIsOpenSignUpModal(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setIsOpenSignUpModal(false)
  }

  const getUrlWithParams = (baseUrl, params) => {
    let url = baseUrl
    const queryParams = new URLSearchParams(params)
    if (queryParams.toString().length > 0) {
      url += `?${queryParams.toString()}`
    }
    return url
  }
  const currentRoute = getUrlWithParams(router.asPath, { lastAction: 'login' })
  useEffect(() => {
    const openSignInorSignUpModal = (event) => {
      const target = event.target
      const href = target?.href
      if (href && (href?.indexOf('#signup') > -1 || href?.indexOf('#signin') > -1)) {
        event.preventDefault()
        if (href.indexOf('#signup') > -1) {
          if (href.indexOf('static/rewards') > -1) {
            redirectToAuth0Register('/rewards')
          } else {
            redirectToAuth0Register()
          }
        }
        if (href.indexOf('#signin') > -1) {
          if (href.indexOf('static/rewards') > -1) {
            redirectToAuth0Login('/rewards')
          } else {
            redirectToAuth0Login(currentRoute)
          }
        }
      }
    }

    document.addEventListener('click', openSignInorSignUpModal, true)

    return () => {
      document.removeEventListener('click', openSignInorSignUpModal)
    }
  }, [])

  return <></>
}

export default SignInSignUpGlobal
